import {
  Application,
  News,
  Profile,
  Project,
  ProjectLeaderProject,
  ProjectLeaderShift,
  Region,
  Shift,
  User,
} from '../types/interfaces'
import { AgreementLabels, AgreementType, ApplicationStatusTranslationKeys, ConfigGlobal } from '../types/types'
import { TFunction } from 'i18next'
import { LocalConfig } from '../utils/loadConfig'

export const LanguageCodeValues = ['en-GB', 'en-IE', 'es-ES', 'fr-BE', 'fr-FR', 'nl-BE', 'pt-PT'] as const
export const LanguageLabels = ['English', 'Español', 'Français', 'Nederlands', 'Português'] as const
export const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const
export const weeklyOccurrence = ['1st', '2nd', '3rd', '4th', '5th', 'Every', 'Alternate'] as const
export const donationURLDefault = 'https://www.servethecity.brussels/donate/'
export const contactEmailDefault = 'hello@servethecity.be'

export const API_BASE_REGION: Region[] = [
  {
    name: 'Belgium',
    apiCode: 'belgium',
    timeZone: 'Europe/Brussels',
    environment: 'production',
  },
  {
    name: 'Belgium UAT',
    apiCode: 'belgium-uat',
    timeZone: 'Europe/Brussels',
    environment: 'uat',
  },
  {
    name: 'France',
    apiCode: 'france',
    timeZone: 'Europe/Paris',
    environment: 'production',
  },
  {
    name: 'France UAT',
    apiCode: 'france-uat',
    timeZone: 'Europe/Paris',
    environment: 'uat',
  },
  {
    name: 'Ireland',
    apiCode: 'ireland',
    timeZone: 'Europe/Dublin',
    environment: 'production',
  },
  {
    name: 'Ireland UAT',
    apiCode: 'ireland-uat',
    timeZone: 'Europe/Dublin',
    environment: 'uat',
  },
  {
    name: 'Portugal',
    apiCode: 'portugal',
    timeZone: 'Europe/Lisbon',
    environment: 'production',
  },
  {
    name: 'Portugal UAT',
    apiCode: 'portugal-uat',
    timeZone: 'Europe/Lisbon',
    environment: 'uat',
  },
  {
    name: 'Spain',
    apiCode: 'spain',
    timeZone: 'Europe/Madrid',
    environment: 'production',
  },
  {
    name: 'Spain UAT',
    apiCode: 'spain-uat',
    timeZone: 'Europe/Madrid',
    environment: 'uat',
  },
]

export const API_PATH = {
  AGREEMENT: 'agreement',
  CODE: 'auth/code',
  LOGIN: 'auth/login',
  SIGNUP: 'auth/signup',
  ACTIVATE: 'auth/activate',
  UPDATE_EMAIL: 'auth/update/email',
  VALIDATE_TOKEN: 'auth/validateToken',
  UPDATE_PASSWORD: 'auth/update/password',
  RESET: 'auth/reset',
  CONFIG: 'config',
  NEWS: 'news',
  LANGUAGES: 'languages',
  PROJECTS: 'projects',
  SHIFTS: 'shifts',
  PROFILE: 'profile',
  PROFILE_OPTIONS: 'profile/options',
  PROFILE_DELETE: 'profile/delete',
  PROJECTS_TAGS: 'projects/tags',
  PROJECTS_OPTIONS: 'projects/options',
  PROJECT_LEADER_PERMISSIONS: 'pl/permissions',
  PROJECT_LEADER_PROJECTS: 'pl/projects',
  PROJECT_LEADER_SHIFTS: 'pl/shifts',
  PROJECT_LEADER_APPLICATIONS: 'applications',
}

export interface AgreementsToCheck {
  type: AgreementType
  dateField: string
  compareDate: Date
}

export const AGREEMENTS_TO_CHECK: AgreementsToCheck[] = [
  {
    type: 'gdpr',
    dateField: 'GDPR_Agreement_Date',
    compareDate: new Date('2024-10-01'),
  },
  {
    type: 'eula',
    dateField: 'EULA_Agreement_Date',
    compareDate: new Date('2024-10-01'),
  },
  {
    type: 'volunteer',
    dateField: 'Volunteer_Agreement_Date',
    compareDate: new Date('2024-10-01'),
  },
]

export const getAgreementLabels = (t: TFunction): AgreementLabels => ({
  eula: {
    title: t('agreement.eula'),
    accept: t('agreement.eulaLong'),
  },
  gdpr: {
    title: t('agreement.gdpr'),
    accept: t('agreement.gdprLong'),
  },
  volunteer: {
    title: t('agreement.volunteerAgreement'),
    accept: t('agreement.volunteerAgreement'),
  },
})

export const applicationStatusTranslationKeys: ApplicationStatusTranslationKeys = {
  Approved: 'approve',
  Rejected: 'reject',
  'On hold': 'onhold',
  Boosted: 'boosted',
  Updated: 'updated',
  Pending: 'pending',
}

export const defaultApplication: Application = {
  ApplicationId: '',
  ApplicationPLComment: '',
  ApplicationArchived: false,
  ApplicationRead: false,
  ApplicationContent: '',
  ApplicationHandledBy: '',
  ApplicationStatus: 'Pending',
  ContactId: '',
  ContactName: '',
  ContactEmail: '',
  ContactMobile: '',
  ContactGender: '',
  ContactPreferredLanguage: '',
  ContactLanguages: [],
  ContactUploaded: [],
  CreatedDate: '',
  ProjectId: '',
  ProjectName: '',
}

export const defaultConfig: ConfigGlobal = {
  belgium: null,
  'belgium-uat': null,
  france: null,
  'france-uat': null,
  ireland: null,
  'ireland-uat': null,
  portugal: null,
  'portugal-uat': null,
  spain: null,
  'spain-uat': null,
}

export const defaultLocalConfig: LocalConfig = {
  API_BASE_URL: 'http://localhost:80/v6.0',
  REGION: 'all',
  UAT: true,
  ENV: 'default',
}

export const defaultProfile: Profile = {
  FirstName: '',
  LastName: '',
  MobilePhone: '',
  Email: '',
  IDNumber: '',
  Birthdate: '',
  MySkills: [],
  MyAvailability: [],
  MyLanguages: [],
  Gender: '',
  MailingAddress: '',
  Affiliation: '',
}

export const defaultProject: Project = {
  ApplicationDate: '',
  ApplicationId: '',
  ApplicationStatus: '',
  Authorization: false,
  DayTime: '',
  Days: [],
  Description: '',
  Frequency: '',
  Image: '',
  Infosession: 0,
  LocationArea: '',
  LocationCity: '',
  LocationInfo: '',
  LocationState: '',
  LocationStreet: '',
  LocationZipCode: '',
  Locations: [],
  Name: '',
  PartnerProject: false,
  ProjectId: '',
  Requirements: [],
  RequiresAuthorization: false,
  RequiresInfosession: false,
  Tags: [],
  TrackOutput: false,
  Featured: false,
}

export const defaultProjectLeaderProject: ProjectLeaderProject = {
  Id: '',
  Name: '',
  Image: '',
  NumberApplications: 0,
  NumberVolunteers: 0,
  NumberOutput: 0,
}

export const defaultProjectLeaderShift: ProjectLeaderShift = {
  ManagedOutput: false,
  ManagedVolunteers: false,
  NumberNeededTotal: 0,
  NumberRegistered: 0,
  ProjectId: '',
  ProjectName: '',
  Remark: '',
  LocationCity: '',
  LocationState: '',
  LocationStreet: '',
  LocationZipCode: '',
  Duration: 0,
  ShiftDescription: '',
  ShiftId: '',
  ShiftName: '',
  StartDateTime: new Date().toISOString(),
  Volunteers: [],
  Cancelled: false,
  RecurringShiftId: '',
}

export const defaultShift: Shift = {
  ShiftId: '',
  ShiftName: '',
  ShiftDescription: '',
  ProjectName: '',
  ProjectId: '',
  DisplayPLs: false,
  NumberNeededStill: 0,
  NumberNeededTotal: 0,
  NumberRegistered: 0,
  Duration: 0,
  StartDateTime: new Date().toISOString(),
  LocationCity: '',
  LocationState: '',
  LocationStreet: '',
  LocationZipCode: '',
  Image: '',
  Remark: '',
  Tags: [],
  Registered: false,
  Status: false,
  Cancelled: false,
}

export const defaultUser: User = {
  Active: false,
  Affiliation: '',
  Birthdate: '',
  ContactId: '',
  Email: '',
  IDNumber: '',
  FirstName: '',
  Gender: '',
  Infosession: 0,
  JWT: '',
  LastName: '',
  MailingAddress: '',
  MobilePhone: '',
  MyAvailability: [],
  MyLanguages: [],
  MySkills: [],
  Name: '',
  ProjectLeader: 0,
  GDPR_Agreement_Date: '',
  Volunteer_Agreement_Date: '',
  EULA_Agreement_Date: '',
  Status: '',
  VolunteerHours: 0,
}

export const defaultNews: News = {
  Content: '',
  Date: '',
  Image: '',
  NewsId: '',
  ProjectId: '',
  ShiftId: '',
  Title: '',
}
